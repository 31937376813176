<template>
  <!-- 内容 -->
  <div class="Box">
    <div class="ptb15 bg-white">
      <div class="f18 fw600 textc">投资人</div>
    </div>
    <!--投资人-->
    <div class="plr20 ptb15 bg-white mt6 bbox">
      <div class="">
        <div class="flex justify-between align-center f12 margin15-b">
          <div class="flex justify-between align-center">
            <img class="user-avatar" src="../assets/images/prodetail02.png" alt="" />
            <div class="margin5-l">王细枝</div>
          </div>
          <div>188****9098</div>
          <div>投资10万元</div>
          <div>2020.12.30</div>
        </div>
        <div class="flex justify-between align-center f12 margin15-b">
          <div class="flex justify-between align-center">
            <img class="user-avatar" src="../assets/images/prodetail02.png" alt="" />
            <div class="margin5-l">王细枝</div>
          </div>
          <div>188****9098</div>
          <div>投资10万元</div>
          <div>2020.12.30</div>
        </div>
        <div class="flex justify-between align-center f12 margin15-b">
          <div class="flex justify-between align-center">
            <img class="user-avatar" src="../assets/images/prodetail02.png" alt="" />
            <div class="margin5-l">王细枝</div>
          </div>
          <div>188****9098</div>
          <div>投资10万元</div>
          <div>2020.12.30</div>
        </div>
        <div class="flex justify-between align-center f12 margin15-b">
          <div class="flex justify-between align-center">
            <img class="user-avatar" src="../assets/images/prodetail02.png" alt="" />
            <div class="margin5-l">王细枝</div>
          </div>
          <div>188****9098</div>
          <div>投资10万元</div>
          <div>2020.12.30</div>
        </div>
      </div>
    </div>
    <div class="f12 f-gray textc margin15-t">暂时没有更多了～</div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import "../assets/css/style.css";
@import "../assets/css/common.css";
</style>